import {NavLink} from "react-router-dom";
import {AccountDetails, LoanBalanceTab, WalletBalanceTab} from "./components";
import {useState} from "react";
import ForeignWalletBalanceTab from "./components/ForeignWalletBalanceTab";


function WalletBalance() {
    const [tab, setTab] = useState(0);

    const btnStyle = `text-[14px] leading-[25px] py-[6px] px-[20px] rounded-[14px]`;

    const handleTabClick = (e: any, _arg: number) => {
        e.preventDefault();
        setTab(_arg);
    };

    const Tabs = () => {
        return (
            <div className="bg-gs-grayLess1 rounded-[14px] flex p-1">
                <button
                    className={`${
                        tab === 0
                            ? "text-gs-primary font-[500] bg-white"
                            : "text-gs-subText font-light bg-transparent"
                    } ${btnStyle}`}
                    onClick={(e) => handleTabClick(e, 0)}
                >
                    My wallet
                </button>
                <button
                    className={`${
                        tab === 2
                            ? "text-gs-primary font-[500] bg-white"
                            : "text-gs-subText font-light bg-transparent"
                    } ${btnStyle}`}
                    onClick={(e) => handleTabClick(e, 2)}
                >
                    Canadian Dollar wallet
                </button>
                <button
                    className={`${
                        tab === 1
                            ? "text-gs-primary font-[500] bg-white"
                            : "text-gs-subText font-light bg-transparent"
                    } ${btnStyle}`}
                    onClick={(e) => handleTabClick(e, 1)}
                >
                    My loan
                </button>
            </div>
        );
    };

    return (
        <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
            <div className="flex justify-between items-center">
                {/* tabs  */}
                <Tabs/>
                <NavLink className="text-gs-primary font-[500]" to="/app/wallet/transaction-history">
                    Transactions history
                </NavLink>
            </div>

            <div className="mt-7">
                {tab === 0 && <WalletBalanceTab/>}
                {tab === 1 && <LoanBalanceTab/>}
                {tab === 2 && <ForeignWalletBalanceTab/>}
            </div>

            <div className="mt-7">
                <AccountDetails/>
            </div>
        </div>
    );
}

export default WalletBalance;
