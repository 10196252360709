import React, {useEffect, useState} from 'react';
import AuthService from '../../../../../services/auth.service';
import NotificationService from '../../../../../services/notification.service';

interface VerificationStatus {
    [email: string]: {
        isVerifying: boolean;
        isVerified: boolean;
        code?: string;
    };
}

interface EmailsSectionProps {
    primaryEmail: string;
    additionalEmails: {
        email2: string;
        email3: string;
        email4: string;
    };
    isEditing: boolean;
    onEmailChange: (key: 'email2' | 'email3' | 'email4', value: string) => void;
    originalEmails: {
        email2: string;
        email3: string;
        email4: string;
    };
    onVerificationStatusChange: (hasUnverifiedEmails: boolean) => void;
}

interface EmailFieldProps {
    email: string;
    emailKey: 'email2' | 'email3' | 'email4';
    isEditing: boolean;
    verificationStatus: VerificationStatus;
    onEmailChange: (key: 'email2' | 'email3' | 'email4', value: string) => void;
    onVerify: (email: string, otp: string) => Promise<void>;
    onSendOTP: (email: string) => Promise<void>;
    isVerified: boolean;
    originalEmail: string;
}

// Styles
const styles = {
    input: "w-full px-3 py-2 text-sm border border-gs-borderInput rounded-lg outline-gs-primaryLess2 disabled:bg-[#ACB5BB]/[.1]",
    button: "px-4 py-2 rounded-lg text-sm font-medium disabled:opacity-50",
};

const EmailField: React.FC<EmailFieldProps> = ({
                                                   email,
                                                   emailKey,
                                                   isEditing,
                                                   verificationStatus,
                                                   onEmailChange,
                                                   onVerify,
                                                   onSendOTP,
                                                   isVerified,
                                                   originalEmail
                                               }) => {
    const [otpValue, setOtpValue] = useState('');
    const status = verificationStatus[email] || {isVerifying: false, isVerified: false};
    const isExistingEmail = !!originalEmail;

    return (
        <div className="space-y-2">
            <div className="flex gap-2">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => onEmailChange(emailKey, e.target.value)}
                    disabled={!isEditing || isVerified || isExistingEmail}
                    placeholder="Additional Email (Optional)"
                    className={`${styles.input} ${isVerified || isExistingEmail ? 'bg-green-50' : ''}`}
                />
                {isEditing && email && !isVerified && !isExistingEmail && (
                    <button
                        onClick={() => onSendOTP(email)}
                        className={`${styles.button} bg-gs-primary text-white whitespace-nowrap`}
                    >
                        Send OTP
                    </button>
                )}
            </div>
            {isEditing && status.isVerifying && !isVerified && !isExistingEmail && (
                <div className="flex gap-2">
                    <input
                        type="text"
                        value={otpValue}
                        onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, '');
                            if (value.length <= 6) {
                                setOtpValue(value);
                            }
                        }}
                        placeholder="Enter verification code"
                        className={styles.input}
                        maxLength={6}
                    />
                    <button
                        onClick={() => {
                            onVerify(email, otpValue);
                            setOtpValue('');
                        }}
                        className={`${styles.button} bg-gs-primary text-white whitespace-nowrap`}
                        disabled={otpValue.length !== 6}
                    >
                        Verify
                    </button>
                </div>
            )}
            {(isVerified || isExistingEmail) && (
                <div className="text-green-600 text-sm flex items-center gap-1">
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
                    </svg>
                    Verified
                </div>
            )}
        </div>
    );
};

export const EmailsSection: React.FC<EmailsSectionProps> = ({
                                                                primaryEmail,
                                                                additionalEmails,
                                                                isEditing,
                                                                onEmailChange,
                                                                originalEmails,
                                                                onVerificationStatusChange
                                                            }) => {
    const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>({});
    const authService = new AuthService();

    // Check for unverified emails and notify parent
    useEffect(() => {
        const hasUnverifiedEmails = Object.entries(additionalEmails).some(([key, email]) => {
            if (!email) return false;
            const isNewEmail = email !== originalEmails[key as keyof typeof originalEmails];
            return isNewEmail && !verificationStatus[email]?.isVerified;
        });
        onVerificationStatusChange(hasUnverifiedEmails);
    }, [additionalEmails, verificationStatus, originalEmails, onVerificationStatusChange]);

    const handleSendOTP = async (email: string) => {
        try {
            const response = await authService.verifyAdditionalEmails({
                additionalEmails: [email]
            });
            if (response.data?.verifications?.[0]?.code) {
                setVerificationStatus(prev => ({
                    ...prev,
                    [email]: {
                        isVerifying: true,
                        isVerified: false,
                        code: response.data.verifications[0].code
                    }
                }));
                NotificationService.success({
                    message: 'Verification code sent'
                });
            }
        } catch (error: any) {
            NotificationService.error({
                message: 'Failed to send verification code',
                addedText: error.message
            });
        }
    };

    function hashOtp(otp: string) {
        const otpDigits = otp.split('').map(Number);
        return otpDigits.reduce((acc, digit, index) => acc + digit * (index + 1), 0);
    }

    const handleVerify = async (email: string, otp: string) => {
        try {
            const expectedOTP = hashOtp(otp);
            if (Number(verificationStatus[email].code) === expectedOTP) {
                setVerificationStatus(prev => ({
                    ...prev,
                    [email]: {...prev[email], isVerified: true, isVerifying: false}
                }));
                NotificationService.success({
                    message: 'Email verified successfully'
                });
            } else {
                NotificationService.error({
                    message: 'Invalid verification code'
                });
            }
        } catch (error: any) {
            NotificationService.error({
                message: 'Verification failed',
                addedText: error.message
            });
        }
    };

    const isEmailVerified = (email: string, key: keyof typeof originalEmails) => {
        if (!email) return false;
        if (email === originalEmails[key]) return true;
        return verificationStatus[email]?.isVerified || false;
    };

    return (
        <div className="space-y-4">
            <input
                type="email"
                value={primaryEmail}
                disabled
                placeholder="Primary Email Address"
                className={styles.input}
            />
            {(Object.keys(additionalEmails) as Array<keyof typeof additionalEmails>).map((key) => (
                <EmailField
                    key={key}
                    email={additionalEmails[key]}
                    emailKey={key}
                    isEditing={isEditing}
                    verificationStatus={verificationStatus}
                    onEmailChange={onEmailChange}
                    onVerify={handleVerify}
                    onSendOTP={handleSendOTP}
                    isVerified={isEmailVerified(additionalEmails[key], key)}
                    originalEmail={originalEmails[key]}
                />
            ))}
        </div>
    );
};

export default EmailsSection;
