import React, {useState} from 'react';
import {getDateString, getTimeFormat} from '../../../../components/custom-hooks';
import {TradeProps} from './_model';
import EmptyState from '../../../../components/ui/EmptyState';
import emptyIcon from '../../../../assets/icons/no-transaction.svg';
import UserService from '../../../../services/user.service';
import NotificationService from '../../../../services/notification.service';
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";

const tableHeaderStyle = "text-[#929EAE] text-[14px] leading-[17.5px] font-semibold";
const tableBodyStyle = "text-[14px] leading-[21px]";
const boxStyle = "px-[2rem] md:w-full w-fit";
const btnStyle = "py-1 px-3 rounded text-[13px] text-white";

interface Props {
    data: TradeProps[];
    refetchSwapHistory: () => void;
}

export default function IncomingTrade({data, refetchSwapHistory}: Props) {
    const userService = new UserService();
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);

    const [selectedTrade, setSelectedTrade] = useState<TradeProps | null>(null);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const handleModalOpen = (item: TradeProps) => {
        setSelectedTrade(item);
    };

    const handleModalClose = () => {
        setSelectedTrade(null);
    };

    const handleAcceptSwap = async (id: number | string) => {
        setAcceptLoading(true);
        try {
            if (!!selectedTrade) {
                if (userInfo.walletBalance < (Number(selectedTrade?.amount) * Number(selectedTrade.exchangeRate))) {
                    NotificationService.error({
                        message: 'Swap Failed!',
                        addedText: "Insufficient funds",
                    });
                    handleModalClose();
                    return;
                }
            }
            const response = await userService.acceptP2pSwap(id);
            setAcceptLoading(false);
            if (!response.status) {
                NotificationService.error({
                    message: 'Swap Failed!',
                    addedText: response.message,
                });
                return;
            }
            refetchSwapHistory();
            NotificationService.success({
                message: response.message,
            });
            handleModalClose();
        } catch (err: any) {
            setAcceptLoading(false);
            NotificationService.error({
                message: 'Swap Failed!',
                addedText: err.message,
            });
        }
    };

    const handleRejectSwap = async (id: number | string) => {
        setRejectLoading(true);
        try {
            const response = await userService.rejectP2pSwap(id);
            setRejectLoading(false);
            if (!response.status) {
                NotificationService.error({
                    message: 'Swap Failed!',
                    addedText: response.message,
                });
                return;
            }
            refetchSwapHistory();
            NotificationService.success({
                message: response.message,
            });
            handleModalClose();
        } catch (err: any) {
            setRejectLoading(false);
            NotificationService.error({
                message: 'Swap Failed!',
                addedText: err.message,
            });
        }
    };

    return (
        <div className="relative">
            <div className={boxStyle}>
                {data?.length > 0 ? (
                    <ul className="grid lg:w-full md:w-[1024px] overflow-x-auto mt-5 ">
                        {/* Table Header */}
                        <li className="flex py-2.5">
                            <div className={`${tableHeaderStyle} w-[20%]`}>AMOUNT</div>
                            <div className={`${tableHeaderStyle} w-[15%]`}>FROM</div>
                            <div className={`${tableHeaderStyle} w-[15%]`}>TO</div>
                            <div className={`${tableHeaderStyle} w-[15%]`}>DATE</div>
                        </li>
                        {data.map((item: TradeProps, index: number) => (
                            <li
                                key={index}
                                className="flex items-center py-2.5 border-t border-[#F5F5F5] cursor-pointer"
                                onClick={() => handleModalOpen(item)}
                            >
                                <div className={`${tableBodyStyle} w-[20%] text-gs-text font-semibold`}>
                                    {item.toCurrency}{item.amount}
                                </div>
                                <div className={`${tableBodyStyle} w-[15%] capitalize`}>{item.fromCurrency}</div>
                                <div className={`${tableBodyStyle} w-[15%] capitalize`}>{item.toCurrency}</div>
                                <div
                                    className={`${tableBodyStyle} w-[15%] flex justify-center capitalize font-semibold`}>
                                    <p className="text-gs-text font-semibold">{getDateString(item.updatedAt)}</p>
                                    <p className="text-gs-subText font-normal">{getTimeFormat(item.updatedAt)}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <EmptyState
                        emptyIcon={emptyIcon}
                        emptyText="You have no P2P history"
                        emptyTextMain="No Available P2P Listings"
                    />
                )}
            </div>

            {/* Modal */}
            {selectedTrade && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-[16px] p-6 w-[90%] max-w-[500px]">
                        <h2 className="text-lg font-bold mb-4">Transaction Details</h2>
                        <p>
                            <strong>Amount:</strong> {selectedTrade.amount}
                        </p>
                        <p>
                            <strong>From:</strong> {selectedTrade.fromCurrency}
                        </p>
                        <p>
                            <strong>To:</strong> {selectedTrade.toCurrency}
                        </p>
                        <p>
                            <strong>Rate:</strong> {selectedTrade.exchangeRate}
                        </p>
                        <p>
                            <strong>Date:</strong> {getDateString(selectedTrade.updatedAt)}{' '}
                            {getTimeFormat(selectedTrade.updatedAt)}
                        </p>

                        <div className="mt-6 flex justify-end gap-4">
                            <button
                                className="bg-gray-200 text-black px-4 py-2 rounded"
                                onClick={handleModalClose}
                                disabled={acceptLoading || rejectLoading}
                            >
                                Close
                            </button>
                            <button
                                className={`bg-green-500 text-white px-4 py-2 rounded ${
                                    acceptLoading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                onClick={() => handleAcceptSwap(selectedTrade.pid)}
                                disabled={acceptLoading}
                            >
                                {acceptLoading ? 'Processing...' : 'Accept'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
