import {request} from "../hooks/api";
import {AccountSetupProps, BankTransferProps, GreenswiftTransferProps, LoanRequestProps} from "./_models";

/**
 *
 * @param {string} url
 * @param {string, [GET, POST, PATCH, PUT...]} method
 * @param {payload} payload
 * @param {boolean} token
 * @param {boolean} text
 * @param {boolean} form
 * @param {string | null} xHash
 * @returns Response Data;
 */


class UserService {
    async accountSetup(payload: AccountSetupProps | {}) {
        try {
            const response = await request(
                '/user/setup',
                'PUT',
                payload,
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async updateUserInfo(payload: any | {}) {
        try {
            return await request(
                '/user/edit',
                'PUT',
                payload,
                true,
                false,
                false,
                null
            );
        } catch (err) {
            throw err;
        }
    }

    async createAccount() {
        try {
            const response = await request(
                '/user/account',
                'POST',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async bvnVerification(payload: { b_id: number | string | undefined }, hash: string) {
        try {
            const response = await request(
                `/user/verify`,
                'POST',
                payload,
                false,
                false,
                false,
                hash
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async verifyAccount(payload: {
        accountNumber: string | number,
        accountBank: string | number
    }) {
        try {
            return await request(
                `/user/verify/bank`,
                'POST',
                payload,
                true,
                false,
                false,
                null
            );
        } catch (err) {
            throw err;
        }
    }

    async getUserInfo() {
        try {
            const response = await request(
                '/user/single',
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getAllBanks() {
        try {
            const response = await request(
                '/user/all/banks',
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async transferToBank(payload: BankTransferProps | {}, hash: string) {
        try {
            const response = await request(
                '/user/transaction/bank',
                'POST',
                payload,
                true,
                false,
                false,
                hash
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async transferToGreenswift(payload: GreenswiftTransferProps | {}, hash: string) {
        try {
            const response = await request(
                '/user/transaction/greenswift',
                'POST',
                payload,
                true,
                false,
                false,
                hash
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async transferToDollar(payload: GreenswiftTransferProps | {}, hash: string) {
        try {
            const response = await request(
                '/user/transaction/dollar',
                'POST',
                payload,
                true,
                false,
                false,
                hash
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async transferToLoanWallet(payload: GreenswiftTransferProps | {}, hash: string) {
        try {
            const response = await request(
                '',           // '/user/transaction/greenswift' , 
                'POST',
                payload,
                true,
                false,
                false,
                hash
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async transactionCharge(payload: { amount: number }) {
        try {
            const response = await request(
                '/user/transaction/charge',
                'POST',
                payload,
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async sendPinAsOTP() {
        try {
            const response = await request(
                '/user/pin/send',
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async pinVerify(payload: { pin: string | number }) {
        try {
            const response = await request(
                '/user/pin/verify',
                'POST',
                payload,
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }


    async generateTrxHash() {
        try {
            const response = await request(
                '/user/transaction/token',
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async allTransactions() {
        try {
            const response = await request(
                '/user/transaction/user',
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async allTransactionsByFilterKey(payload: string) {
        try {
            const response = await request(
                `/user/transaction/user/${payload}`,
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async verifyTag(payload: string) {
        try {
            const response = await request(
                `/user/${payload}`,
                'GET',
                {},
                false,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getTradeHistory() {
        try {
            const response = await request(
                '/user/p2p/all',
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async acceptP2pSwap(id: number | string) {
        try {
            const response = await request(
                `/user/p2p/accept/${id}`,
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async rejectP2pSwap(id: number | string) {
        try {
            const response = await request(
                `/user/p2p/reject/${id}`,
                'GET',
                {},
                true,
                false,
                false,
                null
            )
            return response;
        } catch (err) {
            throw err;
        }
    }

    async requestLoan(payload: LoanRequestProps | {}) {
        try {
            return await request(
                '/user/loan/overdraft',
                'POST',
                payload,
                true,
                false,
                false,
                null
            );
        } catch (err) {
            throw err;
        }
    }


    async repayLoan(payload: LoanRequestProps | {}) {
        try {
            return await request(
                '/user/loan/repay',
                'POST',
                payload,
                true,
                false,
                false,
                null
            );
        } catch (err) {
            throw err;
        }
    }

    async initiateP2pSwap(payload: any | {}, hash: string) {
        try {
            return await request(
                '/user/p2p/initiate',
                'POST',
                payload,
                true,
                false,
                false,
                hash
            );
        } catch (err) {
            throw err;
        }
    }


    async acceptAndCompleteP2pSwap(id: any) {
        try {
            const response = await request(
                `/user/p2p/accept/${id}`,
                'POST',
                {},
                true,
                false,
                false,
                null
            );
            return response;
        } catch (err) {
            throw err;
        }
    }

    // Fetches a list of available P2P swaps
    async listP2pSwaps() {
        try {
            return await request(
                '/user/p2p/list',
                'GET',
                {},
                true,
                false,
                false,
                null
            );
        } catch (err) {
            throw err;
        }
    }

    async myListP2pSwaps() {
        try {
            return await request(
                '/user/p2p/mylist',
                'GET',
                {},
                true,
                false,
                false,
                null
            );
        } catch (err) {
            throw err;
        }
    }

    static async swapCurrency(payload: { amount: number; from: unknown; to: unknown }) {
        try {
            return await request(
                '/user/swap/funds',
                'POST',
                payload,
                true,
                false,
                false,
                null
            );
        } catch (err) {
            throw err;
        }
    }
}


export default UserService;
