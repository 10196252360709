import {useEffect, useState} from "react";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import {Button} from "../../../../../components/ui";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import userService from "../../../../../services/user.service";
import notificationService from "../../../../../services/notification.service";

const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const inputStyle = "flex items-center w-full border rounded-[15px] p-[12px] outline-gs-primary";
const balanceStyle = "text-[12px] font-[700] text-gs-primary bg-gs-primaryLess1 px-3.5 py-1.5 tracking-wide rounded-[16px]";

interface TransferModalProps {
    data?: any,
    closeModal: () => void;
}

function SwapFundModal({closeModal}: TransferModalProps) {
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const [swapFrom, setSwapFrom] = useState("NGN");
    const [swapTo, setSwapTo] = useState("CAD");
    const [amount, setAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (swapFrom === swapTo) {
            setError("Cannot swap same currency");
        } else {
            setError("");
        }
    }, [swapFrom, swapTo]);

    const handleFromCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newFromCurrency = e.target.value;
        setSwapFrom(newFromCurrency);
        // Automatically set the 'to' currency to the opposite of what was selected
        setSwapTo(newFromCurrency === 'NGN' ? 'CAD' : 'NGN');
    };

    const handleToCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newToCurrency = e.target.value;
        setSwapTo(newToCurrency);
        // Automatically set the 'from' currency to the opposite of what was selected
        setSwapFrom(newToCurrency === 'NGN' ? 'CAD' : 'NGN');
    };

    const handleSwap = async (e: React.FormEvent) => {
        e.preventDefault();

        if (swapFrom === swapTo) {
            setError("Cannot swap same currency");
            return;
        }

        setLoading(true);

        try {
            const response = await userService.swapCurrency({
                amount: parseFloat(amount),
                from: swapFrom,
                to: swapTo
            });
            console.log("resp", response)
            notificationService.success({message: response.message || 'Swap successful'});
            closeModal();
        } catch (error: any) {
            notificationService.error({message: error.message || 'Failed to complete swap'});
        } finally {
            setLoading(false);
        }
    }

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and decimal point
            setAmount(value);
        }
    }

    return (
        <form onSubmit={handleSwap}>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <h2 className="text-[18px] font-bold leading-[1rem]">Swap funds</h2>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()}
                    className="hover:cursor-pointer"/>
            </div>

            <div className="mt-7">
                <label className={labelStyle}>Available Balance</label>
                <div className={`${inputStyle} border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                    <p className="text-[14px] font-[500] leading-[14px] tracking-wide">
                        {swapFrom === 'NGN' ? 'Local' : 'Foreign'} wallet
                    </p>
                    <p className={balanceStyle}>
                        {swapFrom === 'NGN'
                            ? `₦${userInfo.walletBalance}`
                            : `$${userInfo.foreignBalance}`
                        }
                    </p>
                </div>
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>

            <div className="flex mt-7 items-center gap-x-5 w-full">
                <div className="w-full">
                    <label className={labelStyle}>Swap from</label>
                    <select
                        onChange={handleFromCurrencyChange}
                        value={swapFrom}
                        className={`${inputStyle} border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                        <option value={'NGN'}>{'NGN'}</option>
                        <option value={'CAD'}>{'CAD'}</option>
                    </select>
                </div>

                <div className="w-full">
                    <label className={labelStyle}>Swap to</label>
                    <select
                        onChange={handleToCurrencyChange}
                        value={swapTo}
                        className={`${inputStyle} border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                        <option value={'CAD'}>{'CAD'}</option>
                        <option value={'NGN'}>{'NGN'}</option>
                    </select>
                </div>
            </div>

            <div className="mt-4">
                <label className={labelStyle}>Amount to Swap</label>
                <div className={`${inputStyle} border-gs-borderInput gap-x-3`}>
                    <div>{swapFrom === 'NGN' ? '₦' : '$'}</div>
                    <input
                        type="text"
                        required
                        value={amount}
                        onChange={handleAmountChange}
                        className="w-full outline-none text-gs-subText"
                        placeholder="0.00"/>
                </div>
            </div>

            <div className="flex flex-wrap lg:justify-end gap-5 mt-7">
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted={true}
                    handleClick={() => closeModal()}
                    text="Cancel"
                    disabled={loading}
                />
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    isInverted={false}
                    type="submit"
                    text={loading ? "Processing..." : "Swap"}
                    disabled={loading || !!error}
                />
            </div>
        </form>
    )
}

export default SwapFundModal;
