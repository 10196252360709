import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "../../../components/ui";
import UploadButton from "../../../assets/icons/UploadButton.svg";
import axios from "axios";
import NotificationService from "../../../services/notification.service";
import { ScaleLoader } from "react-spinners";
import AuthService from "../../../services/auth.service";
import ls from 'localstorage-slim';
import { VerifiedEmailsSection } from './VerifiedEmailsSection';

const countriesArr = require('../../../utils/gr-countries.json');

// REUSABLE STYLING
const styles = {
    labelContainer: "flex flex-col gap-[10px]",
    label: "text-sm leading-[14px] text-gs-text",
    error: "text-[12px] leading-[14px] text-red-700",
    inputContainer: "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6",
    field: "w-full outline-none text-sm leading-[14px] text-gs-subText",
    rowContainer: "flex flex-col md:flex-row max-w-[467px] w-full gap-7 justify-between"
};

const ValidationSchema = Yup.object({
    gender: Yup.string()
        .required("Please select a gender")
        .oneOf(['male', 'female'], 'Please select a valid gender'),
    country: Yup.string()
        .required("Country is required"),
    state: Yup.string()
        .required("State/Province is required"),
    city: Yup.string()
        .required("City is required")
        .min(2, "City name must be at least 2 characters"),
    zipCode: Yup.string()
        .required("Zip Code is required")
        .matches(/^[A-Za-z0-9\s-]*$/, "Invalid zip code format"),
    address: Yup.string()
        .required("Address is required")
        .min(5, "Address must be at least 5 characters"),
    occupation: Yup.string()
        .required("Occupation is required")
        .min(2, "Occupation must be at least 2 characters"),
    dateOfBirth: Yup.string()
        .required("Date of Birth is required")
        .test("age", "You must be at least 18 years old", (value) => {
            if (!value) return false;
            const today = new Date();
            const birthDate = new Date(value);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age >= 18;
        }),
    additionalEmails: Yup.array().when('country', {
        is: 'Canada',
        then: () => Yup.array().of(
            Yup.string()
                .email("Invalid email format")
                .test("unique", "Email must be unique", function (value) {
                    if (!value) return true;
                    const emails: any = this.parent;
                    return emails.filter((email: string) => email === value).length === 1;
                })
        ).min(1, "At least one additional email is required for Canadian residents"),
        otherwise: () => Yup.array()
    }),
});

interface FormValues {
    gender: string;
    country: string;
    state: string;
    city: string;
    zipCode: string;
    address: string;
    occupation: string;
    dateOfBirth: string;
    additionalEmails: {
        email2: string;
        email3: string;
        email4: string;
    };
}

const initialValues: FormValues = {
    gender: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
    occupation: "",
    dateOfBirth: "",
    additionalEmails: {
        email2: "",
        email3: "",
        email4: ""
    },
};

interface AccountSetupFormStepTwoProps {
    onNextStep: () => void;
    setStepOneData: (data: any) => void;
}

function AccountSetupFormStepTwo({ onNextStep, setStepOneData }: AccountSetupFormStepTwoProps) {
    const [hasUnverifiedEmails, setHasUnverifiedEmails] = useState(false);
    const [imageContent, setImageContent] = useState<File | null>(null);
    const [imageError, setImageError] = useState<string | null>(null);
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedState, setSelectedState] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [pendingSubmission, setPendingSubmission] = useState<any>(null);

    const authService = new AuthService();

    useEffect(() => {
        if (selectedCountry !== '') {
            const filteredCountryData = countriesArr.find((country: any) =>
                country.name.toLowerCase() === selectedCountry.toLowerCase()
            );
            setSelectedState(filteredCountryData);
        }
    }, [selectedCountry]);

    const handleForm = async (values: FormValues) => {
        // Image validation
        if (!imageContent) {
            setImageError('Image is required!');
            return;
        }

        // File type and size validation
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
        if (!allowedTypes.includes(imageContent.type)) {
            setImageError('File type must be .jpg, .png, or .pdf');
            return;
        }

        if (imageContent.size > 3 * 1024 * 1024) {
            setImageError('File size must not exceed 3MB');
            return;
        }

        setLoading(true);
        try {
            // Upload file to Cloudinary
            const formData = new FormData();
            formData.append("file", imageContent);
            formData.append("upload_preset", "ize5x0kg");

            const res = await axios.post(
                "https://api.cloudinary.com/v1_1/okafohenrie/image/upload",
                formData
            );

            if (res?.statusText.toLowerCase() !== 'ok') {
                throw new Error('Failed to upload file');
            }

            // Filter out empty emails from the object
            const filteredEmails = Object.entries(values.additionalEmails)
                .reduce((acc, [key, value]) => {
                    if (value.trim() !== '') {
                        acc[key as keyof typeof values.additionalEmails] = value;
                    }
                    return acc;
                }, {} as typeof values.additionalEmails);

            const formPayload = {
                ...values,
                utilityBillUrl: res?.data?.secure_url,
                additionalEmails: filteredEmails
            };

            // Process form submission
            setStepOneData(formPayload);
            onNextStep();
        } catch (err: any) {
            NotificationService.error({
                message: 'Failed!',
                addedText: err?.message
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleForm}
        >
            {({ errors, touched, values, setFieldValue }) => (
                <Form className="py-8 flex flex-col gap-6">
                    {/* Row 1 - Gender & DOB */}
                    <div className={styles.rowContainer}>
                        {/* Gender */}
                        <div className={`${styles.labelContainer} md:max-w-[219px] w-full`}>
                            <div className="flex gap-x-3">
                                <label htmlFor="gender" className={styles.label}>
                                    Gender
                                </label>
                                {errors.gender && touched.gender ? (
                                    <p className={styles.error}>{errors.gender}</p>
                                ) : null}
                            </div>
                            <div className={styles.inputContainer}>
                                <Field
                                    as="select"
                                    id="gender"
                                    name="gender"
                                    className={styles.field}
                                >
                                    <option value="" disabled selected hidden>
                                        Select gender
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </Field>
                            </div>
                        </div>

                        {/* Date of Birth */}
                        <div className={`${styles.labelContainer} md:max-w-[219px] w-full`}>
                            <div className="flex gap-x-3">
                                <label htmlFor="dateOfBirth" className={styles.label}>
                                    Date of Birth
                                </label>
                                {errors.dateOfBirth && touched.dateOfBirth ? (
                                    <p className={styles.error}>{errors.dateOfBirth}</p>
                                ) : null}
                            </div>
                            <div className={styles.inputContainer}>
                                <Field
                                    type="date"
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    className={styles.field}
                                    max={new Date().toISOString().split("T")[0]}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Row 2 - Country & State */}
                    <div className={styles.rowContainer}>
                        {/* Country */}
                        <div className={`${styles.labelContainer} md:max-w-[219px] w-full`}>
                            <div className="flex gap-x-3">
                                <label htmlFor="country" className={styles.label}>
                                    Country/Region
                                </label>
                                {errors.country && touched.country ? (
                                    <p className={styles.error}>{errors.country}</p>
                                ) : null}
                            </div>
                            <div className={styles.inputContainer}>
                                <Field
                                    as="select"
                                    id="country"
                                    name="country"
                                    className={`${styles.field} hover:cursor-pointer`}
                                    value={selectedCountry}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const newCountry = e.target.value;
                                        setFieldValue('country', newCountry);
                                        setSelectedCountry(newCountry);
                                        setFieldValue('state', '');
                                        if (newCountry !== 'Canada') {
                                            setFieldValue('additionalEmails', ["", "", ""]);
                                        }
                                    }}
                                >
                                    <option value="" disabled selected hidden>
                                        Select country
                                    </option>
                                    {countriesArr.map((country: any) => (
                                        <option
                                            className="capitalize"
                                            key={country.id}
                                            value={country.name}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>

                        {/* State */}
                        <div className={`${styles.labelContainer} md:max-w-[219px] w-full`}>
                            <div className="flex gap-x-3">
                                <label htmlFor="state" className={styles.label}>
                                    State/Province
                                </label>
                                {errors.state && touched.state ? (
                                    <p className={styles.error}>{errors.state}</p>
                                ) : null}
                            </div>
                            <div className={styles.inputContainer}>
                                <Field
                                    as="select"
                                    id="state"
                                    name="state"
                                    className={`${styles.field} hover:cursor-pointer`}
                                    disabled={!selectedCountry}
                                >
                                    <option value="" disabled selected hidden>
                                        Select state
                                    </option>
                                    {selectedState?.states?.map((state: any) => (
                                        <option
                                            className="capitalize"
                                            key={state.id}
                                            value={state.name}
                                        >
                                            {state.name}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                    </div>

                    {/* Row 3 - City & Zip Code */}
                    <div className={styles.rowContainer}>
                        {/* City */}
                        <div className={`${styles.labelContainer} md:max-w-[219px] w-full`}>
                            <div className="flex gap-x-3">
                                <label htmlFor="city" className={styles.label}>
                                    City
                                </label>
                                {errors.city && touched.city ? (
                                    <p className={styles.error}>{errors.city}</p>
                                ) : null}
                            </div>
                            <div className={styles.inputContainer}>
                                <Field
                                    id="city"
                                    name="city"
                                    type="text"
                                    placeholder="Enter city"
                                    className={styles.field}
                                />
                            </div>
                        </div>

                        {/* Zip Code */}
                        <div className={`${styles.labelContainer} md:max-w-[219px] w-full`}>
                            <div className="flex gap-x-3">
                                <label htmlFor="zipCode" className={styles.label}>
                                    Zip Code
                                </label>
                                {errors.zipCode && touched.zipCode ? (
                                    <p className={styles.error}>{errors.zipCode}</p>
                                ) : null}
                            </div>
                            <div className={styles.inputContainer}>
                                <Field
                                    id="zipCode"
                                    name="zipCode"
                                    type="text"
                                    placeholder="Enter zip code"
                                    className={styles.field}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Address */}
                    <div className={styles.labelContainer}>
                        <div className="flex gap-x-3">
                            <label htmlFor="address" className={styles.label}>
                                Address
                            </label>
                            {errors.address && touched.address ? (
                                <p className={styles.error}>{errors.address}</p>
                            ) : null}
                        </div>
                        <div className={styles.inputContainer}>
                            <Field
                                id="address"
                                name="address"
                                type="text"
                                placeholder="Enter your full address"
                                className={styles.field}
                            />
                        </div>
                    </div>

                    {/* Occupation */}
                    <div className={styles.labelContainer}>
                        <div className="flex gap-x-3">
                            <label htmlFor="occupation" className={styles.label}>
                                Occupation
                            </label>
                            {errors.occupation && touched.occupation ? (
                                <p className={styles.error}>{errors.occupation}</p>
                            ) : null}
                        </div>
                        <div className={styles.inputContainer}>
                            <Field
                                id="occupation"
                                name="occupation"
                                type="text"
                                placeholder="Enter your occupation"
                                className={styles.field}
                            />
                        </div>
                    </div>

                    {/* Additional Emails for Canada */}
                    {values.country === 'Canada' && (
                        <div className="space-y-4">
                            <div className="bg-gs-shadesOfBlue98 p-4 rounded-lg">
                                <p className="text-sm text-gs-text">
                                    Canadian residents are required to provide at least one additional email address for
                                    verification purposes.
                                </p>
                            </div>
                            <VerifiedEmailsSection
                                additionalEmails={values.additionalEmails}
                                onEmailChange={(key, value) => setFieldValue(`additionalEmails.${key}`, value)}
                                errors={errors.additionalEmails}
                                touched={touched.additionalEmails}
                                onVerificationStatusChange={setHasUnverifiedEmails}
                            />
                        </div>
                    )}

                    {/* Image Upload */}
                    <div className={`${styles.labelContainer} w-full`}>
                        <label htmlFor="image" className={styles.label}>
                            Upload a recent utility bill (.Png, Pdf, Jpg)
                        </label>
                        {imageError && (
                            <p className={styles.error}>{imageError}</p>
                        )}
                        <div className={`${styles.inputContainer} mt-[10px]`}>
                            <input
                                name="image"
                                id="image"
                                accept=".jpg,.jpeg,.png,.pdf"
                                type="file"
                                className="hidden"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        if (file.size > 3 * 1024 * 1024) {
                                            setImageError('File size must not exceed 3MB');
                                            return;
                                        }

                                        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
                                        if (!allowedTypes.includes(file.type)) {
                                            setImageError('File type must be .jpg, .png, or .pdf');
                                            return;
                                        }

                                        setImageContent(file);
                                        setImageError(null);
                                    }
                                }}
                            />
                            <label htmlFor="image" className="cursor-pointer">
                                <div className="flex items-center justify-between">
                                    <p className={styles.field}>
                                        {imageContent ? imageContent.name : "Upload utility bill"}
                                    </p>
                                    <img
                                        src={UploadButton}
                                        alt="upload"
                                        className="w-[78px] h-[33px]"
                                    />
                                </div>
                            </label>
                        </div>

                        {/* Upload Guidelines */}
                        <div className="text-sm text-gray-500 mt-2">
                            <ul className="list-disc pl-5">
                                <li>Maximum file size: 3MB</li>
                                <li>Accepted formats: PNG, JPG, PDF</li>
                                <li>Must be from the last 3 months</li>
                            </ul>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <Button
                        isInverted={false}
                        type="submit"
                        text={loading ? <ScaleLoader color='#fff' height={12} width={4}/> : "Continue"}
                        category="button"
                        btnStyle="w-full py-3 px-6 mt-4"
                        disabled={loading || (values.country === 'Canada' && hasUnverifiedEmails)}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default AccountSetupFormStepTwo;
