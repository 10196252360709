import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import backIcon from "../../../../../assets/icons/arrow-left.svg";
import {Button} from "../../../../../components/ui";
import {useEffect, useState} from "react";
import UserService from "../../../../../services/user.service";
import NotificationService from "../../../../../services/notification.service";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import ls from "localstorage-slim";
import {ScaleLoader} from "react-spinners";

const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const inputStyle = "flex items-center w-full border rounded-[15px]";
const balanceStyle = "text-[12px] font-[700] text-gs-primary bg-gs-primaryLess1 px-3.5 py-1.5 tracking-wide rounded-[16px]";

interface CreateP2PModalProps {
    closeModal: () => void;
    setP2PTab: (data: number) => void;
    setTransferType: (data: string) => void;
}

const initialValues = {
    fromCurrency: "CAD",
    toCurrency: "NGN",
    amount: 0.0,
    exchangeRate: 1700,
};

ls.config.encrypt = true;

function CreateP2PModal({closeModal, setP2PTab, setTransferType}: CreateP2PModalProps) {
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const userService = new UserService();
    const [formData, setFormData] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [isAmountValid, setIsAmountValid] = useState(true);

    const handleAmountChange = (event: any) => {
        const value = event.target.value;
        const pattern = /^\d+(\.\d+)?$/;

        if (pattern.test(value) || value === '') {
            setIsAmountValid(true);
            setFormData({...formData, amount: parseFloat(value)});
        } else {
            setIsAmountValid(false);
        }
    };

    const handlePay = async (event: any) => {
        event.preventDefault();
        if (formData.amount > Number(userInfo.foreignBalance)) {
            NotificationService.warn({message: "Insufficient balance"});
            return;
        }
        setLoading(true);
        try {
            await userService.sendPinAsOTP();
            ls.set("_tix", {...formData});
            NotificationService.success({message: "OTP sent for confirmation"});
            setP2PTab(4);
            setTransferType("p2p");
        } catch (err) {
            NotificationService.error({message: "Transfer failed!"});
        } finally {
            setLoading(false);
        }
    };


    return (
        <form onSubmit={handlePay}>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <div className="text-[18px] font-bold flex items-center gap-x-3">
                    <img src={backIcon} alt="back" onClick={() => setP2PTab(0)} className="cursor-pointer"/>
                    Back
                </div>
                <img src={closeIcon} alt="close" onClick={closeModal} className="cursor-pointer"/>
            </div>

            <h2 className="text-[18px] font-bold tracking-wide">Create P2P Transaction</h2>

            <div className="mt-7">
                <label className={`${labelStyle}`}>From Currency</label>
                <input
                    type="text"
                    placeholder="Currency to swap from"
                    value={formData.fromCurrency}
                    onChange={(e) => setFormData({...formData, fromCurrency: e.target.value})}
                    className={`${inputStyle} px-[12px] py-[15px]`}
                    required
                    disabled
                />
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>To Currency</label>
                <input
                    type="text"
                    placeholder="Currency to swap to"
                    value={formData.toCurrency}
                    onChange={(e) => setFormData({...formData, toCurrency: e.target.value})}
                    className={`${inputStyle} px-[12px] py-[15px]`}
                    required
                    disabled
                />
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Amount</label>
                <div className={`${inputStyle} p-[12px] ${isAmountValid ? 'border-gs-borderInput' : 'border-red-600'}`}>
                    <input
                        type="text"
                        className="w-full outline-none text-gs-subText"
                        placeholder="Enter amount"
                        value={formData.amount.toString()}
                        onChange={handleAmountChange}
                        required
                    />
                </div>
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Exchange Rate (Optional)</label>
                <input
                    type="text"
                    placeholder="Enter exchange rate if applicable"
                    value={formData.exchangeRate || ''}
                    onChange={(e) => setFormData({
                        ...formData,
                        exchangeRate: e.target.value ? parseFloat(e.target.value) : 1700
                    })}
                    className={`${inputStyle} px-[12px] py-[15px]`}
                />
            </div>

            <div className="flex flex-wrap justify-end gap-5 mt-7">
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted
                    onClick={closeModal}
                    text="Cancel"
                />
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    type="submit"
                    text={loading ? <ScaleLoader color="#fff" height={12} width={4}/> : "Create Transaction"}
                    isInverted={false}
                />
            </div>
        </form>
    );
}

export default CreateP2PModal;
