import { useState } from "react";
import dropdownIcon from "../../../assets/icons/arrow-down.svg";
import notificationIcon from "../../../assets/icons/notification.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import logoutIcon from "../../../assets/icons/logout.svg";
import polygonIcon from "../../../assets/icons/polygon.svg";
import author from "../../../assets/images/user-avatar.png";
import { NavLink, useNavigate } from "react-router-dom";
import NotificationService from "../../../services/notification.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

function RightPane({ text }: any) {
    const navigate = useNavigate();
    const [ toggle, setToggle ] = useState(false);
    const userInfo = useSelector((state: RootState) => state.auth.userInfo)

    const handleLogout = () => {
        NotificationService.success({
            message: 'Logout successful!'
        })
        localStorage.clear();
        navigate('/auth/signin');
    }

    const dropdownStyle = "flex font-normal gap-x-3 text-[#9096A2] items-center hover:bg-gs-grayLess1 hover:cursor-pointer  px-4 py-1.5 text-[15px] tracking-wide";

    return (
        <nav className="w-full bg-white px-6 py-4 flex justify-between items-center">
            <div>
                {text}
            </div>
            <div className="flex justify-between items-center gap-x-6">
                {/* notification icon  */}
                <img src={notificationIcon} alt="notification" />

                <div className="relative">
                    <div className="flex items-center gap-x-2 bg-gray-100 py-2 px-2 rounded-full" >
                        <img src={userInfo.image || author} alt="avatar" className="w-[30px] h-[30px] rounded-full" />
                        <p className="text-[13.5px] tracking-normal capitalize">{`${userInfo.fullName}`}</p>
                        <img src={dropdownIcon} alt="down" className="hover:cursor-pointer" onClick={() => setToggle(!toggle)} />
                    </div>

                    {/* dropdown toggle  */}
                   { toggle && 
                    <div className="absolute z-[999] w-[170px] top-9 right-0">
                        <img
                            src={polygonIcon}
                            alt="angle"
                            className="relative z-2 justify-center mx-auto top-1 mb-0" 
                            />
                        <div className="border-[.5px] border-[#E4E4E7] py-2.5 w-full bg-white shadow-md rounded-[12px]">
                            <NavLink to="/app/settings" className={`${dropdownStyle}`}>
                                <img src={profileIcon} alt="profile-img" />
                                <p>Profile</p>
                            </NavLink>
                            <div className={`${dropdownStyle}`} onClick={handleLogout}>
                                <img src={logoutIcon} alt="logout-img" />
                                <p>Logout</p>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </nav>
    )
}

export default RightPane;
