import React, { useState, useEffect } from 'react';
import AuthService from '../../../services/auth.service';
import NotificationService from '../../../services/notification.service';

interface VerificationStatus {
    [email: string]: {
        isVerifying: boolean;
        isVerified: boolean;
        code?: string;
    };
}

interface AdditionalEmails {
    email2: string;
    email3: string;
    email4: string;
}

interface EmailFieldProps {
    email: string;
    emailKey: keyof AdditionalEmails;
    verificationStatus: VerificationStatus;
    onChange: (key: keyof AdditionalEmails, value: string) => void;
    onVerify: (email: string, otp: string) => Promise<void>;
    onSendOTP: (email: string) => Promise<void>;
    error?: string;
    touched?: boolean;
    isRequired?: boolean;
}

const styles = {
    input: "w-full outline-none text-sm leading-[14px] text-gs-subText",
    button: "px-4 py-2 rounded-lg text-sm font-medium text-white bg-gs-primary disabled:opacity-50",
    container: "flex flex-col gap-2",
    inputContainer: "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6",
    label: "text-sm leading-[14px] text-gs-text",
    error: "text-[12px] leading-[14px] text-red-700",
    verifiedBadge: "text-green-600 text-sm flex items-center gap-1"
};

const EmailField: React.FC<EmailFieldProps> = ({
                                                   email,
                                                   emailKey,
                                                   verificationStatus,
                                                   onChange,
                                                   onVerify,
                                                   onSendOTP,
                                                   error,
                                                   touched,
                                                   isRequired
                                               }) => {
    const [otpValue, setOtpValue] = useState('');
    const status = verificationStatus[email] || { isVerifying: false, isVerified: false };
    const displayNumber = emailKey.replace('email', '');

    return (
        <div className={styles.container}>
            <label htmlFor={emailKey} className={styles.label}>
                Additional Email {displayNumber} {isRequired ? '(Required)' : '(Optional)'}
            </label>
            {error && touched && (
                <p className={styles.error}>{error}</p>
            )}
            <div className="flex gap-2">
                <div className={styles.inputContainer}>
                    <input
                        id={emailKey}
                        type="email"
                        value={email}
                        onChange={(e) => onChange(emailKey, e.target.value)}
                        placeholder="Enter additional email address"
                        className={styles.input}
                    />
                </div>
                {email && !status.isVerified && (
                    <button
                        onClick={() => onSendOTP(email)}
                        className={styles.button}
                        type="button"
                    >
                        Send OTP
                    </button>
                )}
            </div>

            {status.isVerifying && !status.isVerified && (
                <div className="flex gap-2">
                    <div className={styles.inputContainer}>
                        <input
                            type="text"
                            value={otpValue}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, '');
                                if (value.length <= 6) {
                                    setOtpValue(value);
                                }
                            }}
                            placeholder="Enter verification code"
                            className={styles.input}
                            maxLength={6}
                        />
                    </div>
                    <button
                        onClick={() => {
                            onVerify(email, otpValue);
                            setOtpValue('');
                        }}
                        className={styles.button}
                        disabled={otpValue.length !== 6}
                        type="button"
                    >
                        Verify
                    </button>
                </div>
            )}

            {status.isVerified && (
                <div className={styles.verifiedBadge}>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
                    </svg>
                    Verified
                </div>
            )}
        </div>
    );
};

interface VerifiedEmailsSectionProps {
    additionalEmails: AdditionalEmails;
    onEmailChange: (key: keyof AdditionalEmails, value: string) => void;
    errors?: { [key: string]: string };
    touched?: { [key: string]: boolean };
    onVerificationStatusChange: (hasUnverifiedEmails: boolean) => void;
}

export const VerifiedEmailsSection: React.FC<VerifiedEmailsSectionProps> = ({
                                                                                additionalEmails,
                                                                                onEmailChange,
                                                                                errors = {},
                                                                                touched = {},
                                                                                onVerificationStatusChange
                                                                            }) => {
    const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>({});
    const authService = new AuthService();

    useEffect(() => {
        const hasUnverifiedEmails = Object.entries(additionalEmails)
            .some(([_, email]) => email && !verificationStatus[email]?.isVerified);
        onVerificationStatusChange(hasUnverifiedEmails);
    }, [additionalEmails, verificationStatus, onVerificationStatusChange]);

    const handleSendOTP = async (email: string) => {
        try {
            const response = await authService.verifyAdditionalEmails({
                additionalEmails: [email]
            });
            if (response.data?.verifications?.[0]?.code) {
                setVerificationStatus(prev => ({
                    ...prev,
                    [email]: {
                        isVerifying: true,
                        isVerified: false,
                        code: response.data.verifications[0].code
                    }
                }));
                NotificationService.success({
                    message: 'Verification code sent'
                });
            }
        } catch (error: any) {
            NotificationService.error({
                message: 'Failed to send verification code',
                addedText: error.message
            });
        }
    };

    const handleVerify = async (email: string, otp: string) => {
        try {
            const status = verificationStatus[email];
            if (!status?.code) return;

            const expectedOTP = hashOtp(otp);
            if (Number(status.code) === expectedOTP) {
                setVerificationStatus(prev => ({
                    ...prev,
                    [email]: { ...prev[email], isVerified: true, isVerifying: false }
                }));
                NotificationService.success({
                    message: 'Email verified successfully'
                });
            } else {
                NotificationService.error({
                    message: 'Invalid verification code'
                });
            }
        } catch (error: any) {
            NotificationService.error({
                message: 'Verification failed',
                addedText: error.message
            });
        }
    };

    return (
        <div className="space-y-4">
            {(Object.keys(additionalEmails) as Array<keyof AdditionalEmails>).map((key, index) => (
                <EmailField
                    key={key}
                    email={additionalEmails[key]}
                    emailKey={key}
                    verificationStatus={verificationStatus}
                    onChange={onEmailChange}
                    onVerify={handleVerify}
                    onSendOTP={handleSendOTP}
                    error={errors[key]}
                    touched={touched[key]}
                    isRequired={index === 0}
                />
            ))}
        </div>
    );
};

function hashOtp(otp: string) {
    const otpDigits = otp.split('').map(Number);
    return otpDigits.reduce((acc, digit, index) => acc + digit * (index + 1), 0);
}

export default VerifiedEmailsSection;
