import CardDetails from "./CardDetails";
import Card from "./Card";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface WalletBalanceProps {
  tab: number;
  setTab: (data: number) => void;
}

function CardType({ tab, setTab }: WalletBalanceProps) {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo)
  const btnStyle = `text-[14px] leading-[25px] py-[6px] px-[20px] rounded-[14px]`;
  const handleTabClick = (e: any, _arg: number) => {
    e.preventDefault();
    setTab(_arg);
  };

  const Tabs = () => {
    return (
      <div className="bg-gs-grayLess1 rounded-[14px] flex p-1">
        <button
          className={`${
            tab === 0
              ? "text-gs-primary font-[500] bg-white"
              : "text-gs-subText font-light bg-transparent"
          } ${btnStyle}`}
          onClick={(e) => handleTabClick(e, 0)}
        >
          Naira Tab
        </button>
        <button
          className={`${
            tab === 1
              ? "text-gs-primary font-[500] bg-white"
              : "text-gs-subText font-light bg-transparent"
          } ${btnStyle}`}
          onClick={(e) => handleTabClick(e, 1)}
        >
          CAD Tab
        </button>
      </div>
    );
  };

  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <div className="flex justify-between items-center">
        {/* tabs  */}
        <Tabs />
      </div>

      <div className="mt-7">
          {tab === 0 && (
              <Card
                  currency={`${userInfo.localCurrencySymbol || '...'}${Number(userInfo.walletBalance || 0).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                  })}`}
                  type={0}
                  className="bg-gs-primary"
              />
          )}
          {tab === 1 && (
              <Card
                  currency={`${userInfo.foreignCurrencySymbol || '...'}${Number(userInfo.foreignBalance || 0).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                  })}`}
                  type={1}
                  className="bg-gs-cardBg"
              />
          )}
      </div>

      {/*<div className="mt-7">*/}
      {/*  <CardDetails />*/}
      {/*</div>*/}
    </div>
  );
}

export default CardType;
