
import { request } from "../hooks/api";
import { Values } from "../pages/auth/components/SignUpForm";

/**
 *
 * @param {string} url
 * @param {string, [GET, POST, PATCH, PUT...]} method
 * @param {payload} payload
 * @param {boolean} token
 * @param {boolean} text
 * @param {boolean} form
 * @param {string | null} xHash
 * @returns Response Data;
 */


interface AccountSetupValues {
    country: string,
    state: string,
    zipCode: string,
    city: string,
    gender: string,
    dateOfBirth: string | Date,
    address: string,
    occupation: string,
    utilityBillUrl: string,
    idImgUrl: string
}


class AuthService {
    async login(payload: {email: string, password: string}) {
        try {
            const response = await request(
                '/auth/login' , 
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    }

    async register(payload: Values) {
        try {
            const response = await request(
                '/auth/register' , 
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    }

    async verifyEmail(payload: {email: string}) {
        try {
            const response = await request(
                '/auth/verifyEmail' , 
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    }

    async verifyTag(payload: { username: string | undefined}) {
        try {
            const response = await request(
                `/auth/verifyTag` , 
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    } 

    async sendOTP(payload: {email: string, phone: string}) {
        try {
            const response = await request(
                '/auth/verify' , 
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    }

    async verifyAdditionalEmails(payload: {additionalEmails: string[]}) {
        try {
            const response = await request(
                '/auth/verifyAdditionalEmails' ,
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    }

    async forgotPassword(payload: { email: string }) {
        try {
            const response = await request(
                '/auth/reset-otp' , 
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    }

    async passwordReset(payload: { email: string | undefined, newPassword: string }) {
        try {
            const response = await request(
                '/auth/reset' , 
                'POST',
                payload,
                false,
                false,
                false,
                null
            )
            return response;
        }catch(err){
            throw err;
        }
    }

    async accountSetup(payload: AccountSetupValues) {
        try {
            const response = await request(
                '/user/setup' , 
                'POST',
                payload,
                false,
                false,
                false
            )
            return response;
        }catch(err){
            throw err;
        }
    } 

}


export default AuthService;
