import { NavLink } from "react-router-dom";
import { MonthSelect } from "./components";
import DonutGraph from "./components/DonutGraph";
import GraphLine from "./components/LineGraph";
import { useState, useEffect } from "react";
import _ from 'lodash';

interface Transaction {
    tid: number;
    Date: string;
    amount: string;
    serviceType: string;
    statusType: string;
    status: string;
    isForeign: boolean;
}

interface UserInfo {
    Transactions: Transaction[];
    walletTag: string;
    tiers: string;
    country: string;
    [key: string]: any;
}

function StatisticsSection({ userInfo }: { userInfo: UserInfo }) {
    const [selectedMonth, setSelectedMonth] = useState<string>("all");
    const [graphData, setGraphData] = useState<number[]>([0, 0, 0, 0, 0]);
    const [donutData, setDonutData] = useState<number[]>([0, 0, 0]);
    const [totalAmount, setTotalAmount] = useState<number>(0);

    useEffect(() => {
        if (userInfo?.Transactions) {
            processTransactions();
        }
    }, [selectedMonth, userInfo?.Transactions]);

    const getLastFiveMonths = () => {
        const months = [];
        const today = new Date();
        for (let i = 4; i >= 0; i--) {
            const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
            months.push(date.toISOString().slice(0, 7)); // YYYY-MM format
        }
        return months;
    };

    const processTransactions = () => {
        const transactions = userInfo?.Transactions || [];
        const lastFiveMonths = getLastFiveMonths();

        // Filter transactions based on selected month
        const filteredTransactions = transactions.filter(transaction => {
            const transactionDate = new Date(transaction.Date);
            const transactionMonth = transactionDate.toLocaleString('default', { month: 'long' }).toLowerCase();

            if (selectedMonth === 'all') {
                return true;
            }
            return transactionMonth === selectedMonth.toLowerCase();
        });

        // Process Line Graph Data (Monthly totals)
        const monthlyData = lastFiveMonths.map(monthKey => {
            return transactions
                .filter(t => t.Date.startsWith(monthKey))
                .reduce((sum, t) => {
                    const amount = parseFloat(t.amount) || 0;
                    return t.statusType === 'Credit' ? sum + amount : sum - amount;
                }, 0);
        });

        // Process Donut Chart Data (Transaction types)
        const serviceTypeGroups = _.groupBy(filteredTransactions, 'serviceType');
        const serviceTypeAmounts = Object.entries(serviceTypeGroups)
            .map(([_, transactions]) =>
                transactions.reduce((sum, t) => sum + (parseFloat(t.amount) || 0), 0)
            )
            .sort((a, b) => b - a)
            .slice(0, 3);

        // Calculate total amount for selected period
        const periodTotal = filteredTransactions.reduce((sum, t) => {
            const amount = parseFloat(t.amount) || 0;
            return t.statusType === 'Credit' ? sum + amount : sum - amount;
        }, 0);

        // Update state
        setGraphData(monthlyData.map(amount => Math.abs(amount)));
        setDonutData(serviceTypeAmounts);
        setTotalAmount(Math.abs(periodTotal));
    };

    const handleMonth = (month: string) => {
        setSelectedMonth(month.toLowerCase());
    };

    return (
        <div className="w-full relative z-[1] overflow-x-auto">
            <div className="h-full grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-[2rem]">
                <div className="lg:col-span-3 md:col-span-2 col-span-1 bg-white border border-gs-borderInput rounded-[14.5px]">
                    <div className="flex justify-between items-end pl-5 pr-3 pt-4">
                        <p className="text-[16px] font-semibold text-[#0A112F]">Analysis</p>
                        <MonthSelect setMonth={handleMonth} />
                    </div>
                    <h2 className="pl-5 text-[27px] font-bold py-1">
                        ₦{totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h2>
                    <div className="w-full pb-3">
                        <GraphLine data={graphData} />
                    </div>
                </div>

                <div className="lg:col-span-2 col-span-1 bg-white border border-gs-borderInput rounded-[14.5px]">
                    <div className="flex justify-between items-start px-5 pt-4 mb-2">
                        <p className="text-[16px] font-semibold text-[#0A112F]">Summary</p>
                        <NavLink
                            to="/app/settings"
                            className="text-gs-primary text-[12px] font-normal tracking-wide"
                        >
                            View details
                        </NavLink>
                    </div>
                    <div className="grid gap-y-3 pl-5 pr-3">
                        <div className="border-l-[3px] border-[#31B099] px-2">
                            <p className="text-[11px] font-normal">Account Tier</p>
                            <p className="text-[19px] font-[500]">{userInfo?.tiers}</p>
                        </div>
                        <div className="border-l-[3px] border-gs-primary px-2">
                            <p className="text-[11px] font-normal">Referral code</p>
                            <p className="text-[19px] font-[500]">{userInfo?.referralCode}</p>
                        </div>
                        <div className="border-l-[3px] border-gs-primary px-2">
                            <p className="text-[11px] font-normal">Country</p>
                            <p className="text-[19px] font-[500]">{userInfo?.country}</p>
                        </div>
                    </div>

                    <div className="px-5">
                        <DonutGraph data={donutData}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatisticsSection;
