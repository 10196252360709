import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import UserService from '../../../../services/user.service';
import NotificationService from '../../../../services/notification.service';
import { setUserInfo } from '../../../../redux/reducers/authReducer';
import EmailsSection from './components/EmailSection';

const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/<your_cloud_name>/upload";
const CLOUDINARY_UPLOAD_PRESET = "<your_upload_preset>";

interface FormData {
    name: {
        first: string;
        middle: string;
        last: string;
    };
    tag: string;
    email: string;
    email2: string;
    email3: string;
    email4: string;
    phone: string;
    address: string;
    dob: string;
    gender: string;
    country: string;
    img: string | null;
}

// Styles
const styles = {
    input: "w-full px-3 py-2 text-sm border border-gs-borderInput rounded-lg outline-gs-primaryLess2 disabled:bg-[#ACB5BB]/[.1]",
    label: "text-[14px] font-medium text-[#1B212D]",
    subText: "text-[12px] text-[#929EAE]",
    container: "grid grid-cols-4 gap-4 items-start py-2",
    section: "mb-3",
    button: "px-4 py-2 rounded-lg text-sm font-medium disabled:opacity-50",
};

const BasicInformationTab: React.FC = () => {
    const initialState: FormData = {
        name: { first: '', middle: '', last: '' },
        tag: '',
        email: '',
        email2: '',
        email3: '',
        email4: '',
        phone: '',
        address: '',
        dob: '',
        gender: '',
        country: '',
        img: null,
    };

    const [formData, setFormData] = useState<FormData>(initialState);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [hasUnverifiedEmails, setHasUnverifiedEmails] = useState(false);
    const [originalEmails, setOriginalEmails] = useState({
        email2: '',
        email3: '',
        email4: ''
    });

    const userService = new UserService();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        try {
            const response = await userService.getUserInfo();
            const userData = response.data;

            setFormData({
                name: {
                    first: userData.firstName || '',
                    middle: userData.middleName || '',
                    last: userData.surname || ''
                },
                tag: userData.walletTag || '',
                email: userData.email || '',
                email2: userData.email2 || '',
                email3: userData.email3 || '',
                email4: userData.email4 || '',
                phone: userData.phone || '',
                address: userData.address || '',
                dob: userData.dob || '',
                gender: userData.gender || '',
                country: userData.country || '',
                img: userData.img || null,
            });

            setOriginalEmails({
                email2: userData.email2 || '',
                email3: userData.email3 || '',
                email4: userData.email4 || ''
            });

            if (userData.status) {
                dispatch(setUserInfo(userData));
            }
        } catch (err: any) {
            NotificationService.error({
                message: 'Failed to fetch user information',
                addedText: err.message
            });
        }
    };

    const handleAdditionalEmailChange = (key: 'email2' | 'email3' | 'email4', value: string) => {
        setFormData(prev => ({ ...prev, [key]: value }));
    };

    const handleVerificationStatusChange = (hasUnverified: boolean) => {
        setHasUnverifiedEmails(hasUnverified);
    };

    const handleSubmit = async () => {
        if (hasUnverifiedEmails) {
            NotificationService.error({
                message: 'Please verify all new email addresses before saving'
            });
            return;
        }

        setIsSubmitting(true);
        try {
            let imageUrl = formData.img;

            if (imageFile) {
                const formDataImg = new FormData();
                formDataImg.append('file', imageFile);
                formDataImg.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

                const response = await fetch(CLOUDINARY_URL, {
                    method: 'POST',
                    body: formDataImg,
                });
                const data = await response.json();
                imageUrl = data.secure_url;
            }

            const updatedData = {
                ...formData,
                img: imageUrl,
                firstName: formData.name.first,
                middleName: formData.name.middle,
                surname: formData.name.last,
            };

            const response = await userService.updateUserInfo(updatedData);

            if (response.status) {
                NotificationService.success({
                    message: 'Profile updated successfully'
                });
                setIsEditing(false);
                dispatch(setUserInfo(response.data));
                setOriginalEmails({
                    email2: formData.email2,
                    email3: formData.email3,
                    email4: formData.email4
                });
            }
        } catch (error: any) {
            NotificationService.error({
                message: 'Update failed',
                addedText: error.message
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="p-4 bg-white border border-gs-borderInput rounded-lg">
            {/* Profile Section */}
            <div className={styles.section}>
                <div className="flex items-center gap-4">
                    <div className="relative h-16 w-16 rounded-full">
                        <div className="h-full w-full rounded-full bg-gs-primary flex items-center justify-center text-xl text-white overflow-hidden">
                            {formData.img ? (
                                <img
                                    className="h-full w-full object-cover"
                                    src={formData.img}
                                    alt="user-avatar"
                                />
                            ) : (
                                formData.name.first.charAt(0) + formData.name.last.charAt(0)
                            )}
                        </div>
                        {isEditing && (
                            <label className="absolute bottom-0 right-0 bg-gs-primary text-white rounded-full p-1.5 cursor-pointer">
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file && file.size <= 3 * 1024 * 1024) {
                                            setImageFile(file);
                                        } else {
                                            NotificationService.error({
                                                message: 'File too large',
                                                addedText: 'Maximum file size is 3MB'
                                            });
                                        }
                                    }}
                                />
                                <svg className="w-4 h-4" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12 20h9v-2h-9v2zm0-6h9v-2h-9v2zm0-6h9V6h-9v2zm-3 14l-5-5h3V3h4v14h3l-5 5z"/>
                                </svg>
                            </label>
                        )}
                    </div>
                    <div>
                        <h2 className="text-lg font-semibold text-gs-text">Personal Information</h2>
                        <p className="text-sm text-gray-500">Manage your profile information</p>
                    </div>
                </div>
            </div>

            {/* Name Fields */}
            <div className={styles.container}>
                <div className="col-span-1">
                    <label className={styles.label}>Full Name</label>
                    <span className={styles.subText}>Your legal name</span>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-3">
                    {['first', 'middle', 'last'].map((type) => (
                        <input
                            key={type}
                            type="text"
                            value={formData.name[type as keyof typeof formData.name]}
                            onChange={(e) => setFormData({
                                ...formData,
                                name: { ...formData.name, [type]: e.target.value }
                            })}
                            disabled={!isEditing}
                            placeholder={`${type.charAt(0).toUpperCase() + type.slice(1)} name`}
                            className={styles.input}
                        />
                    ))}
                </div>
            </div>

            {/* Tag & Email Section */}
            <div className={styles.container}>
                <div className="col-span-1">
                    <label className={styles.label}>Email Addresses</label>
                    <span className={styles.subText}>Your email accounts</span>
                </div>
                <div className="col-span-3">
                    <input
                        type="text"
                        value={formData.tag}
                        disabled
                        placeholder="GreenSwift Tag"
                        className={`${styles.input} mb-2`}
                    />
                    <EmailsSection
                        primaryEmail={formData.email}
                        additionalEmails={{
                            email2: formData.email2,
                            email3: formData.email3,
                            email4: formData.email4
                        }}
                        isEditing={isEditing}
                        onEmailChange={handleAdditionalEmailChange}
                        originalEmails={originalEmails}
                        onVerificationStatusChange={handleVerificationStatusChange}
                    />
                </div>
            </div>

            {/* Contact Details */}
            <div className={styles.container}>
                <div className="col-span-1">
                    <label className={styles.label}>Contact Details</label>
                    <span className={styles.subText}>How to reach you</span>
                </div>
                <div className="col-span-3 space-y-2">
                    <input
                        type="tel"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        disabled={!isEditing}
                        placeholder="Phone Number"
                        className={styles.input}
                    />
                    <input
                        type="text"
                        value={formData.address}
                        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                        disabled={!isEditing}
                        placeholder="Address"
                        className={styles.input}
                    />
                </div>
            </div>

            {/* Personal Details */}
            <div className={styles.container}>
                <div className="col-span-1">
                    <label className={styles.label}>Personal Details</label>
                    <span className={styles.subText}>About you</span>
                </div>
                <div className="col-span-3 grid grid-cols-2 gap-3">
                    <select
                        value={formData.gender}
                        onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                        disabled={!isEditing}
                        className={styles.input}
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                    <input
                        type="date"
                        value={formData.dob}
                        onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                        disabled={!isEditing}
                        className={styles.input}
                    />
                </div>
            </div>

            {/* Country Selection */}
            <div className={styles.container}>
                <div className="col-span-1">
                    <label className={styles.label}>Location</label>
                    <span className={styles.subText}>Your residence</span>
                </div>
                <div className="col-span-3">
                    <select
                        value={formData.country}
                        onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                        disabled={!isEditing}
                        className={styles.input}
                    >
                        <option value="">Select Country</option>
                        <option value="Canada">Canada</option>
                        <option value="United States">United States</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="United Kingdom">United Kingdom</option>
                    </select>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-3 mt-6">
                {!isEditing ? (
                    <button
                        onClick={() => setIsEditing(true)}
                        className={`${styles.button} bg-gs-primary text-white`}
                    >
                        Edit Profile
                    </button>
                ) : (
                    <>
                        <button
                            onClick={() => {
                                setIsEditing(false);
                                fetchUserInfo();
                                setImageFile(null);
                            }}
                            className={`${styles.button} bg-gray-100`}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            disabled={isSubmitting || hasUnverifiedEmails}
                            className={`${styles.button} bg-gs-primary text-white`}
                        >
                            {isSubmitting ?
                                <ScaleLoader color='#fff' height={12} width={4} /> :
                                'Save Changes'
                            }
                        </button>
                    </>
                )}
            </div>

            {hasUnverifiedEmails && isEditing && (
                <div className="text-red-500 text-sm mt-2 text-center">
                    Please verify all new email addresses before saving
                </div>
            )}
        </div>
    );
};

export default BasicInformationTab;
