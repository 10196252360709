import React, {useState} from "react";
import {Button} from "../../../components/ui";
import NotificationService from "../../../services/notification.service";
import ls from "localstorage-slim";
import AuthService from "../../../services/auth.service";

interface Props {
    text?: any;
    handleVerification?: (data: string | number) => void;
    loading?: boolean;
    forTrx?: boolean;
}

function VerificationForm({text, handleVerification, loading, forTrx}: Props) {
    const [codeBox, setCodeBox] = useState(new Array(6).fill(""));
    const authService = new AuthService();

    const handleNext = () => {
        const isCodeValid = codeBox.every((value) => value.trim() !== "");
        if (!isCodeValid) {
            alert("Input an OTP");
            return;
        }
        if (handleVerification) {
            const otpInput = codeBox.join('');
            handleVerification(otpInput);
        }
    };

    const handleResend = async () => {
        // send verification email
        const epObj = JSON.parse(ls.get('gs-reg-email-phone') as string);
        console.log("epObj", epObj);
        const otp_response = await authService.sendOTP({email: epObj.email, phone: epObj.phone})
        if (!otp_response.status) {
            NotificationService.error({
                message: 'Failed to send OTP!',
                addedText: otp_response.message
            });
            return;
        }

        if (otp_response.data.sent) {
            ls.set('gs-reg-ot', otp_response.data.code);
            NotificationService.success({
                message: 'OTP sent successfully',
            });
            return;
        }
    };

    const handleChange = (element: HTMLInputElement, index: number): void => {
        if (isNaN(Number(element.value))) return;

        setCodeBox((prevCodeBox) =>
            prevCodeBox.map((d, idx) => (idx === index ? element.value : d))
        );

        // Focus next input
        if (element.nextSibling instanceof HTMLInputElement) {
            element.nextSibling.focus();
        }
    };

    function handleDelete(
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ): void {
        if (
            e.key === "Backspace" &&
            e.currentTarget.previousSibling instanceof HTMLInputElement
        ) {
            e.preventDefault();
            e.currentTarget.previousSibling.focus();

            setCodeBox((prevCodeBox) =>
                prevCodeBox.map((data, i) => (i !== index ? data : ""))
            );
        } else if (
            e.key === "Backspace" &&
            !e.currentTarget.previousSibling &&
            e.currentTarget.nextSibling instanceof HTMLInputElement
        ) {
            e.preventDefault();
            setCodeBox((prevCodeBox) =>
                prevCodeBox.map((data, i) => (i !== index ? data : ""))
            );
        } else if (e.key === "Enter") {
        }
    }

    function handlePaste(e: any) {
        const value = e.clipboardData.getData("text");
        if (isNaN(value)) return false;
        const updatedValue = value.toString().split("").slice(0, codeBox.length);
        setCodeBox(updatedValue);

        const focusedInput = e.target.parentNode.querySelector("input:focus");
        if (focusedInput) {
            focusedInput.blur();
        }

        const lastInput = e.target.parentNode.querySelector(
            'input[type="password"]:last-child'
        );
        if (lastInput) {
            lastInput.focus();
        }
    }

    return (
        <>
            <div className="flex md:gap-[19px] gap-[7px] my-10">
                {codeBox.map((value, i) => (
                    <input
                        type="text"
                        className="w-full h-[62px] border py-[15px] px-4 border-gs-borderInput outline-gs-primary rounded-[15px] flex items-center justify-center text-center text-[14px] md:text-[16px]"
                        key={i}
                        value={value}
                        maxLength={1}
                        onChange={(e) => handleChange(e.target, i)}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                            const target = e.target as HTMLInputElement;
                            if (e.keyCode === 8 || e.key === "Backspace") {
                                handleDelete(e, i);
                            } else if (e.key === "ArrowLeft" && target.previousSibling) {
                                e.preventDefault();
                                (target.previousSibling as HTMLInputElement).focus();
                            } else if (e.key === "ArrowRight" && target.nextSibling) {
                                e.preventDefault();
                                (target.nextSibling as HTMLInputElement).focus();
                            }
                        }}
                        onPaste={(e) => {
                            handlePaste(e);
                        }}
                    />
                ))}
            </div>
            <Button
                isInverted={false}
                text={text}
                disabled={loading}
                category="button"
                btnStyle="w-full py-3 px-6"
                handleClick={handleNext}
            />

            {!forTrx &&
                <Button
                    isInverted={true}
                    text={"Resend OTP"}
                    disabled={loading}
                    category="button"
                    btnStyle="w-full py-3 px-6"
                    handleClick={handleResend}
                />
            }

        </>
    );
}

export default VerificationForm;
