import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppLayout} from "../../../../components/layouts";
import UserService from "../../../../services/user.service";
import {setUserInfo} from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";
import Tabs from "./Tabs";
import {Button, CustomModal} from "../../../../components/ui";
import IncomingTrade from "./IncomingTrade";
import OutgoingTrade from "./OutgoingTrade";
import {RootState} from "../../../../redux/store";
import ModalCreateP2PSwap from "./components/ModalCreateP2PSwap";
import ConfirmTransaction from "./components/ConfirmTransaction";
import ls from "localstorage-slim";

function P2P() {
    const dispatch = useDispatch();
    const userService = new UserService();
    const userInfo: any = useSelector((state: RootState) => state.auth.userInfo);

    const tabContainerStyle = "w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem] flex justify-between items-center";
    const boxStyle = "px-[2rem] py-[4rem] md:w-full w-fit";

    const [tab, setTab] = useState(0);
    const [tradeModal, setTradeModal] = useState(false);
    const [transferTab, setTransferTab] = useState(0);
    const [incomingData, setIncomingData] = useState([]);
    const [outgoingData, setOutgoingData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [pinVerifyLoading, setPinVerifyLoading] = useState(false);
    const [transferType, setTransferType] = useState("");

    const getUserInfo = async () => {
        try {
            const response = await userService.getUserInfo();
            if (response.status) {
                dispatch(setUserInfo(response.data));
            }
        } catch (err: any) {
            NotificationService.error({
                message: "Failed to load user information",
                addedText: err?.message,
            });
        }
    };

    const getTradeHistory = async () => {
        if (!userInfo?.uid) return; // Ensure userInfo is loaded

        setIsLoading(true); // Start loading
        try {
            const response = await userService.listP2pSwaps();
            const response2 = await userService.myListP2pSwaps();
            if (!response.status) {
                NotificationService.error({
                    message: "Failed to load trade history",
                    addedText: response?.message,
                });
                return;
            }

            const outgoingArr = response2.data;
            const incomingArr = response?.data

            setIncomingData(incomingArr);
            setOutgoingData(outgoingArr);
        } catch (err: any) {
            NotificationService.error({
                message: "Failed to load trade history",
                addedText: err?.message,
            });
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        getUserInfo();
    }, []);

    useEffect(() => {
        if (userInfo?.uid) {
            getTradeHistory();
        }
    }, [userInfo]);

    const refetchSwapHistory = () => {
        getTradeHistory();
    };

    const closeTransferModal = () => {
        setTradeModal(false);
        setTransferTab(0);
    };

    const handleTransaction = async (_arg: string | number) => {
        // Existing handleTransaction logic...
    };

    return (
        <AppLayout
            text={<h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">P2P Trading</h2>}
        >
            <div className="relative">
                <div className={boxStyle}>
                    {/* Tabs and New P2P button */}
                    <div className={tabContainerStyle}>
                        <Tabs tab={tab} setTab={(data) => setTab(data)}/>
                        <Button
                            btnStyle="px-[30px] py-[10px] md:w-fit w-full border border-gs-primary"
                            category="button"
                            isInverted={false}
                            handleClick={() => setTradeModal(true)}
                            text="New P2P"
                        />
                    </div>
                </div>

                <div className={boxStyle}>
                    {isLoading ? (
                        <div className="text-center py-10">Loading trade data...</div>
                    ) : (
                        <>
                            {tab === 0 && (
                                <IncomingTrade refetchSwapHistory={refetchSwapHistory} data={incomingData}/>
                            )}
                            {tab === 1 && <OutgoingTrade data={outgoingData}/>}
                        </>
                    )}
                </div>

                {tradeModal && (
                    <CustomModal
                        modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[13%] bg-white rounded-[16px] p-[25px]"
                        closeModal={closeTransferModal}
                    >
                        {transferTab === 0 && (
                            <ModalCreateP2PSwap
                                closeModal={closeTransferModal}
                                setP2PTab={(data) => setTransferTab(data)}
                                setTransferType={(data) => setTransferType(data)}
                            />
                        )}
                        {transferTab === 4 && (
                            <ConfirmTransaction
                                closeModal={closeTransferModal}
                                handlePinVerification={handleTransaction}
                                loading={pinVerifyLoading}
                            />
                        )}
                    </CustomModal>
                )}
            </div>
        </AppLayout>
    );
}

export default P2P;
